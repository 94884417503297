import { isMobile } from 'react-device-detect';
import { FILTER_TYPES } from '../../shared/constants';
import { useDispatch, useSelector } from 'react-redux';
import { a_effectSelected } from '../../api/analytics';
import { setEffect, updateApp } from '../../redux/app/actions';

import './frame-select/FrameSelect.scss';
import effectImage from '../../assets/images/effect-image.png';

const filters = Object.keys(FILTER_TYPES);

export const FrameEffects = () => {
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);
  const { selectedEffect } = useSelector((state) => state.app);
  const { isCheckoutOpen } = useSelector((state) => state.checkout);

  const updateEffect = (effect) => {
    dispatch(setEffect(effect));
  };

  const handleSelectEffect = (effect) => {
    updateEffect(effect);
  };

  return (
    <div
      style={{
        zIndex: 100,
        left: '50%',
        background: 'white',
        position: 'absolute',
        transform: 'translate(-50%)',
        padding: '16px 0px 16px 16px',
        borderRadius: '20px 20px 0px 0px',
        width: isMobile ? '100%' : '450px',
        ...(isMobile ? { paddingBottom: 0 } : {}),
        boxShadow: '0px 1px 59px rgba(35, 33, 66, 0.1)',
      }}
      className={
        app.modals.effect ? 'frame-select-slideUp' : 'frame-select-slideDown'
      }
    >
      <div
        style={{ display: 'flex', marginBottom: '20px', paddingRight: '16px' }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            flexDirection: 'column',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
        >
          Select Effect
        </div>
        <div
          style={{
            color: '#A638FC',
            fontSize: '16px',
            marginLeft: 'auto',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
          onClick={() => {
            dispatch(updateApp({ modals: { effect: !app.modals.effect } }));
          }}
        >
          Done
        </div>
      </div>
      <div
        style={{ overflow: 'hidden' }}
        className={`frame-select ${isCheckoutOpen && isMobile && 'hide'}`}
      >
        <div
          className="frames-container hide-scrollbar"
          style={{
            gap: '12px',
            overflowX: 'auto',
          }}
        >
          {filters.map((effect) => {
            return (
              <div
                key={effect}
                // style={{
                //   display: !isSizeActive ? 'none' : 'block',
                // }}
              >
                <div
                  style={{
                    gap: '20px',
                    height: '100%',
                    display: 'flex',
                    cursor: 'pointer',
                    textAlign: 'center',
                  }}
                  onClick={() => {
                    handleSelectEffect(effect);
                    a_effectSelected(effect);
                  }}
                  className={`single-frame ${selectedEffect == effect && 'selected'}`}
                >
                  <div
                    className="shadow"
                    style={{
                      width: '100px',
                      height: '100px',
                      display: 'flex',
                      overflow: 'hidden',
                      borderRadius: '8px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border:
                        selectedEffect != effect
                          ? '1px solid rgba(0,0,0,.2)'
                          : '3px solid #AD37FD',
                    }}
                  >
                    <img
                      alt="icon"
                      width="100%"
                      height="100%"
                      src={effectImage}
                      className={effect}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: '16px',
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                        fontWeight: selectedEffect == effect ? 900 : 500,
                        color:
                          selectedEffect == effect
                            ? '#A637FC'
                            : 'rgba(0,0,0,.8)',
                      }}
                    >
                      {effect}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ paddingBottom: '16px' }}></div>
    </div>
  );
};
