import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import * as routes from '../../../app/routes';
import { appActions } from '../../../redux/app';
import toggleChatPopup from '../../../utils/toggleChatPopup';
import { pixelSetup } from '../../../api/facebook-pixel';
import Header from '../../components/header';
import Spinner from '../../components/spinner';
import Upsell from '../../components/upsell';
import Boards from '../../pages/boards';
import AddCouponModal, {
  AddVIPCouponModal,
} from '../../components/addCouponModal/AddCouponModal';
import Gift from '../../pages/gift/Gift';
import { CustomerPortal } from '../../pages/CustomerPortal';
import { ValidateAuthentication } from '../../../context/ValidateAuthentication';

const utmParams = window.location.search.substring(1);

const RootRouter = ({
  app,
  urlParams,
  initializeApp,
  getInfo,
  checkout,
  user,
  coupon,
  updateApp,
}) => {
  const { coupon: coup } = coupon;
  const { platform, remarketingId, selectedSize } = app;
  const { addCoupon, addVIPCoupon, upsell } = app.modals;
  const [showSpinner, setShowSpinner] = useState(true);
  const {
    isCheckoutOpen,
    checkingout,
    checkedout,
    totalPrice,
    klarnaLoading,
    oneTimeOrSubscription,
  } = checkout;

  useEffect(() => {
    toggleChatPopup(!isCheckoutOpen, 'isCheckoutOpen');
  }, [isCheckoutOpen]);

  useEffect(() => {
    getInfo(urlParams, remarketingId, oneTimeOrSubscription, coup);
    updateApp({ utmParams });
    if (!app.initialized) initializeApp();

    window.GorgiasChat.init().then(() => {
      let chatElem = document.getElementById('gorgias-chat-container');
      if (chatElem) chatElem.style.display = 'none';
    });

    const spinnerTimer = setTimeout(() => {
      setShowSpinner(false);
    }, 2000);

    return () => clearTimeout(spinnerTimer);
  }, []);

  useEffect(() => {
    getInfo(
      urlParams,
      remarketingId,
      oneTimeOrSubscription,
      coup,
      oneTimeOrSubscription,
    );
  }, [selectedSize]);

  useEffect(() => {
    if (user.initialized) {
      pixelSetup(user);
    }
  }, [user.initialized]);

  const shouldShowBackdrop = isCheckoutOpen;
  const shouldShowVIPBackdrop = addVIPCoupon || addCoupon;
  const upsellEnabled =
    !checkingout && checkedout && totalPrice && coup?.type !== 'CARD';

  return showSpinner ? (
    <Spinner homeClass="-initial" />
  ) : (
    <>
      {window.location.pathname !== '/portal' ? (
        <Header platform={platform} />
      ) : null}
      <CSSTransition
        unmountOnExit
        timeout={200}
        in={shouldShowVIPBackdrop}
        classNames="popup-backdrop-animation"
      >
        <div className="popup-backdrop" />
      </CSSTransition>
      {addCoupon && <AddCouponModal />}
      {addVIPCoupon && <AddVIPCouponModal />}
      <ValidateAuthentication />
      <Switch>
        <Route exact path={routes.GIFT} component={Gift} />
        <Route exact path={routes.HOME} component={Boards} />
        <Route exact path={routes.PORTAL} component={CustomerPortal} />
        <Route path={routes.HOME_WITH_COUPON} component={Boards} />
      </Switch>
      {klarnaLoading && (
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 9999,
            width: '100%',
            height: '100%',
            position: 'fixed',
            background: '#fff',
          }}
        >
          <Spinner homeClass="-initial" />
        </div>
      )}
      <CSSTransition
        unmountOnExit
        mountOnEnter
        in={upsellEnabled && upsell}
        classNames={'sb-Upsell-transition'}
        timeout={1000}
      >
        <Upsell />
      </CSSTransition>
    </>
  );
};

const mapStateToProps = ({ app, user, router, coupon, checkout }) => ({
  app,
  user,
  coupon,
  checkout,
  urlParams: router.location.query,
});

const mapDispatchToProps = (dispatch) => ({
  initializeApp: () => dispatch(appActions.initializeApp()),
  getInfo: (urlParams, id, oneTimeOrSubscription, coupon, override) =>
    dispatch(
      appActions.getInfo(
        urlParams,
        id,
        oneTimeOrSubscription,
        coupon,
        override,
      ),
    ),
  updateApp: (payload) => dispatch(appActions.updateApp(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootRouter);
