import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import {
  GetIdCommand,
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
} from '@aws-sdk/client-cognito-identity';

const identityPoolId = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;

const cognitoIdentityClient = new CognitoIdentityClient({
  region: process.env.REACT_APP_AWS_REGION,
});

let cachedIdentityId = null;
let cachedCredentials = null;

// Function to check if the credentials are expired
const areCredentialsExpired = (credentials) => {
  const currentTime = Date.now() / 1000; // Current time in seconds
  return credentials.Expiration < currentTime;
};

// Function to get or refresh Cognito credentials
const getCognitoCredentials = async () => {
  // If we already have cached credentials and they're not expired, use them
  if (cachedCredentials && !areCredentialsExpired(cachedCredentials)) {
    return cachedCredentials;
  }

  // If no IdentityId is cached, or credentials are expired, fetch new ones
  if (!cachedIdentityId || areCredentialsExpired(cachedCredentials)) {
    // Fetch new IdentityId if not cached
    if (!cachedIdentityId) {
      const getIdCommand = new GetIdCommand({
        IdentityPoolId: identityPoolId,
      });

      const { IdentityId } = await cognitoIdentityClient.send(getIdCommand);
      cachedIdentityId = IdentityId; // Cache the IdentityId for reuse
    }

    // Fetch new credentials for the IdentityId
    const credentialsCommand = new GetCredentialsForIdentityCommand({
      IdentityId: cachedIdentityId,
    });

    const { Credentials } =
      await cognitoIdentityClient.send(credentialsCommand);

    // Cache the credentials and return them
    cachedCredentials = Credentials;
    return Credentials;
  }

  // Return the existing valid credentials if they're not expired
  return cachedCredentials;
};

export const s3Upload = async (params) => {
  const credentials = await getCognitoCredentials();

  const s3Client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: credentials.AccessKeyId,
      secretAccessKey: credentials.SecretKey,
      sessionToken: credentials.SessionToken,
    },
  });

  const command = new PutObjectCommand({
    ...params,
  });

  await s3Client.send(command);

  return `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.Key}`;
};
