import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { StripeProvider } from 'react-stripe-elements';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Notifications } from '@mantine/notifications';
import { Alert, MantineProvider } from '@mantine/core';
import { jwtDecode } from 'jwt-decode';

import cookie from 'universal-cookie';

import { AuthProvider } from '../context/AuthContext';

import RootRouter from '../views/routers/root-router';
import history from './history';
import initStore from '../redux/store';
import { a_init } from '../api/analytics';
import { isDevelopment } from '../utils/envHandler';
import { IconInfoCircle } from '@tabler/icons-react';
import { AuthenticationProvider } from '../context/AuthenticationProvider';

export const store = initStore();
const stripeApiKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const Cookies = new cookie();

const App = () => {
  a_init();
  const cookie = Cookies.get('_p');
  const [loggedIn, setLoggedIn] = useState(
    cookie ? jwtDecode(Cookies.get('_p')) : {},
  );
  const [hideAlert, setHideAlert] = useState(false);
  const logout = () => {
    Cookies.remove('_p', { path: '/' });
    setLoggedIn(undefined);
  };
  return (
    <AuthenticationProvider value={{ logout, loggedIn, setLoggedIn }}>
      <AuthProvider>
        <MantineProvider>
          <Provider store={store}>
            <Notifications position="bottom-center" />
            <ConnectedRouter history={history}>
              <PayPalScriptProvider
                options={{
                  clientId: process.env.REACT_APP_PAYPAL_API_KEY,
                  components: 'buttons,messages',
                  disableFunding: 'card,ideal,paylater,sofort',
                  currency: 'USD',
                  intent: 'capture',
                }}
              >
                {/* {isDevelopment ? (
                  <Alert
                    radius="xs"
                    color="grape"
                    variant="light"
                    withCloseButton
                    hidden={hideAlert}
                    icon={<IconInfoCircle />}
                    onClose={() => setHideAlert(true)}
                    styles={{
                      message: {
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                      },
                    }}
                  >
                    Analytics is disabled
                  </Alert>
                ) : null} */}
                <StripeProvider apiKey={stripeApiKey}>
                  <RootRouter />
                </StripeProvider>
              </PayPalScriptProvider>
            </ConnectedRouter>
          </Provider>
        </MantineProvider>
      </AuthProvider>
    </AuthenticationProvider>
  );
};

export default App;
